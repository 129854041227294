"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHATML_PROMPT_EXTRA_TOKEN_COUNT_CONSTANT = exports.CHATML_PROMPT_EXTRA_TOKEN_COUNT_LINEAR_FACTOR = exports.ChatCompletionRequestMessageRoleEnum = exports.Configuration = exports.OpenAIApi = void 0;
exports.approximateTokensUsingBytecount = approximateTokensUsingBytecount;
exports.hasImages = hasImages;
exports.hasNoImages = hasNoImages;
var openai_1 = require("openai");
// Setup
Object.defineProperty(exports, "OpenAIApi", { enumerable: true, get: function () { return openai_1.OpenAIApi; } });
Object.defineProperty(exports, "Configuration", { enumerable: true, get: function () { return openai_1.Configuration; } });
Object.defineProperty(exports, "ChatCompletionRequestMessageRoleEnum", { enumerable: true, get: function () { return openai_1.ChatCompletionRequestMessageRoleEnum; } });
// tokenizers
const encoder = new TextEncoder();
function approximateTokensUsingBytecount(text, tokenizer) {
    const byteLength = encoder.encode(text).length;
    switch (tokenizer) {
        case 'cl100k_base':
        case 'o200k_base':
            return byteLength / 4;
        default:
            return byteLength / 3;
    }
}
// docs here: https://platform.openai.com/docs/guides/chat/introduction (out of date!)
// linear factor is <|im_start|>system<|im_sep|>  and <|im_end|>
exports.CHATML_PROMPT_EXTRA_TOKEN_COUNT_LINEAR_FACTOR = 4;
// this is <|im_start|>assistant<|im_sep|>
exports.CHATML_PROMPT_EXTRA_TOKEN_COUNT_CONSTANT = 3;
function hasImages(message) {
    return typeof message.content !== 'string';
}
function hasNoImages(message) {
    return typeof message.content === 'string';
}
