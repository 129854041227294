"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OutputCatcherImpl = void 0;
class OutputCatcherImpl {
    constructor() {
        this.outputs = [];
        this.noPriorityOutputs = [];
    }
    async onOutput(output, options) {
        if ((options === null || options === void 0 ? void 0 : options.p) !== undefined) {
            this.outputs.push({ output, priority: options.p });
            this.outputs.sort((a, b) => b.priority - a.priority);
        }
        else {
            this.noPriorityOutputs.push({ output, priority: null });
        }
    }
    getOutputs() {
        return [...this.outputs, ...this.noPriorityOutputs].map((o) => o.output);
    }
    getOutput() {
        return this.outputs.length > 0
            ? this.outputs[0].output
            : this.noPriorityOutputs.length > 0
                ? this.noPriorityOutputs[0].output
                : undefined;
    }
}
exports.OutputCatcherImpl = OutputCatcherImpl;
// @cursor-agent {"dependsOn": "interface", "hash": "7034e4452cc668449b0b967116683a95303c4509d263ed535851b081164751bb"}
