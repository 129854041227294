"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.statsd = void 0;
exports.statsd = new Proxy({}, {
    get: (target, prop) => {
        return (...args) => {
            // Do nothing
        };
    }
});
