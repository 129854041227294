"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewOutputCatcher = NewOutputCatcher;
const outputCatcher_ai_impl_1 = require("./outputCatcher.ai.impl");
// @cursor-agent {"dependsOn": "interface", "hash": "7034e4452cc668449b0b967116683a95303c4509d263ed535851b081164751bb"}
// @cursor-agent {"dependsOn": "implementation", "hash": "083f9244af4f56b541391df75e2a6bfe7e352f5ee6ed3ffe2eabd36dc06cdcf8"}
function NewOutputCatcher() {
    return new outputCatcher_ai_impl_1.OutputCatcherImpl();
}
// @cursor-agent {"dependsOn": "interface", "hash": "7034e4452cc668449b0b967116683a95303c4509d263ed535851b081164751bb"}
// @cursor-agent {"passedInitialVerification": true}
// @cursor-agent {"dependsOn": "allFiles", "hash": "bd574f28bd5ebd5a493044d7bdaf54e43529f4b849ae540d9a5df45b9ad44ad1"}
// @cursor-agent {"passedAllTests": true}
